@import './Variables.scss';

.contactContainer {
  margin-top: 5rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  max-width: 1200px;
  width: 100vw;
  &_info {
    &--words {
      font-family: $font-family;
      color: $black-color;
      font-size: 0.8rem;
      height: 2rem;
      text-decoration: none;
    }
    &--icon {
      margin-right: 0.5rem;
      transform: translateY(0.1rem);
      color: $primary-color;
      font-size: 1.1rem;
    }
  }
  &_formTitle {
    font-family: $font-family;
    font-weight: 600;
    font-size: 1.5rem;
    color: $black-color;
  }
  &_formText {
    font-family: $font-family;
    text-align: center;
    letter-spacing: 0.07rem;
    line-height: 1.3rem;
    width: 70%;
    max-width: 400px;
  }
  &_form {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    width: 70%;
    max-width: 600px;

    &--label {
      color: $black-color;
      font-family: $font-family;
      font-size: 0.8rem;
      font-weight: 500;
    }

    &--input {
      background-color: rgba(165, 217, 217, 0.5);
      border: 1px solid $primary-color;
      border-radius: 5px;
    }
    &--inputMessage {
      background-color: rgba(165, 217, 217, 0.5);
      border: 1px solid $primary-color;
      border-radius: 5px;
      height: 60px;
    }
    &--btn {
      align-self: center;
      width: 130px;
      height: 30px;
      background-color: $primary-color;
      border: none;
      border-radius: 15px;
      cursor: pointer;
      color: white;
      //margin-bottom: 2rem;
      margin-top: 10px;
    }
    &--btn:hover {
      background-color: transparent;
      border: 1px solid $primary-color;
      color: $primary-color;
    }
  }
  &_succesMessage {
    font-family: $font-family;
    margin: auto;
    font-size: 1.5rem;
    text-align: center;
    color: $primary-color-2;
  }
}
.openLinkedin:hover {
  cursor: pointer;
  color: $tertiary-color;
}
input::placeholder {
  color: rgb(187, 185, 185);
  font-size: 0.6rem;
  font-family: $font-family;
  letter-spacing: 0.5px;
}
textarea::placeholder {
  color: rgb(187, 185, 185);
  font-size: 0.6rem;
  padding-top: 4px;
  font-family: $font-family;
  letter-spacing: 0.5px;
}
@media (min-width: 768px) {
  .contactContainer {
    margin-top: 0;
  }
}
