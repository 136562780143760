@import '../Variables.scss';

.section1Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: white;
  padding-top: 5.5rem;
  &_hi {
    font-family: $font-family;
    font-weight: 600;
    letter-spacing: 0.1rem;
    font-size: 2rem;
    color: $black-color;
    width: 100%;
    text-align: center;
    animation: slideInLeft;
    animation-duration: 2s;
  }
  &_me {
    font-family: $font-family;
    color: $black-color;
    letter-spacing: 0.1rem;
    font-size: 1.5rem;
    width: 100%;
    text-align: center;
    line-height: 2rem;
    animation: slideInRight;
    animation-duration: 2s;
  }

  &_img {
    width: 100%;
    animation: slideInLeft;
    animation-duration: 2s;
  }
}

@media (min-width: 768px) {
  .section1Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    &_img {
      width: 60vw;
    }
    &_hi {
      font-size: 3rem;
      margin-bottom: 1rem;
      text-align: right;
    }
    &_me {
      text-align: right;
    }
  }
}
