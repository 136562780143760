@import '../Variables.scss';

.section2Container {
  background-color: white;
  padding-top: 2rem;
  padding: 4rem;
  display: flex;
  justify-content: center;
  &_link {
    text-decoration: none;
    font-family: $font-family;
    color: $primary-color-3;
    width: 150px;
    height: 150px;
    background-color: $primary-color;
    border-radius: 50%;
    position: relative;

    padding: 10px;
    &--text {
      position: absolute;
      width: 90%;
      height: 90%;
      fill: white;
      font-family: $font-family;
      letter-spacing: 0.05rem;
      font-weight: 200;
      font-size: 0.71rem;
    }
    &--icon {
      position: absolute;
    }
  }
}

.circleSvg {
  width: 150px;
  overflow: visible;
  cursor: pointer;
}

.circlePath {
  opacity: var(--show, 0);
  transition: opacity 0.2s;
  background-color: transparent;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotate {
  animation: rotate 20s linear infinite;
}
.section2Container_link:hover {
  transform: scaleZ(-2);
  box-shadow: 0 0 10px $primary-color;
  background-color: $tertiary-color;
}
