@import 'Variables.scss';

.navContainer {
  background-color: $primary-color;
  opacity: 0.95;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;

  box-shadow: 0 1px 8px 1px #1d313181;
  position: fixed;
  top: 0;

  max-width: 1200px;
  margin: auto;
  width: 100vw;
  z-index: 1000;
  height: 4.5rem;
  &_logo {
    grid-column-start: 1;
    grid-column-end: 2;
    width: 55px;
    height: 35px;
    transform: translateY(6px);
    padding-left: 30px;
    margin-top: 20px;
    cursor: pointer;
  }
  &_menuBtn {
    grid-column-start: 3;
    grid-column-end: 4;
    justify-self: right;
    border: none;
    height: 30px;
    width: 30px;
    background-color: transparent;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1001;
  }
}
.hide {
  display: none;
}
.hamburger-menu-icon {
  color: white;
  font-size: 30px;
}

.openMenu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  background-color: $primary-color;
  z-index: 99;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  &_closeMenuBtn {
    background-color: transparent;
    border: 2px solid white;
    border-radius: 50%;
    width: 2.6rem;
    height: 2.6rem;
    font-size: 1.8rem;
    margin-top: 50px;
    font-family: $font-family;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &_list {
    &--names {
      margin: 30px;
    }
    &--link {
      text-decoration: none;
      color: white;
      font-family: $font-family;
      font-size: 1.6rem;
    }
  }
}
@media (min-width: 768px) {
  .navContainer {
    &_menu {
      gap: 20px;
      grid-column-start: 2;
      grid-column-end: 4;
      justify-content: right;
      margin-right: 2rem;
      &--names {
      }
      &--link {
        color: white;
        text-decoration: none;
        font-family: $font-family;
        font-size: 1.2rem;
        cursor: pointer;
      }
      &--link:hover {
        text-decoration-line: underline;
      }
    }
    &_menuBtn {
      display: none;
    }
  }
  .hide {
    display: flex;
    align-items: center;
  }
}
