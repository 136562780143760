@import '../Variables.scss';

.section3Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem 0 4rem 0;
  background-color: white;
  &_btn {
    width: 130px;
    height: 30px;
    background-color: $primary-color;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    color: white;
    &--link {
      text-decoration: none;
      color: white;
    }
    &--link:hover {
      text-decoration: none;
      color: $primary-color;
    }
    &--icon {
      transform: translateY(0.08rem);
      margin-right: 0.3rem;
    }
  }
  &_btn:hover {
    background-color: transparent;
    border: 1px solid $primary-color;
    color: $primary-color;
  }
}
