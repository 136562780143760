.thumbnail-carousel {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.thumbnail-carousel img {
  width: 50px;
  height: 50px;
  margin: 0 5px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.thumbnail-carousel img:hover {
  opacity: 0.7;
}

.thumbnail-active {
  border: 3px solid $primary-color-3;
}
