$primary-color: #027373;
$primary-color-2: #04acac;
$primary-color-3: #a5d9d9;
$secondary-color: #d95252;
$secondary-color-2: #e07373;
$secondary-color-3: #e8b7b7;
$tertiary-color: #f2dc5d;
$tertiary-color-2: #f3e8a4;
$black-color: #1d3131;

$font-family: 'Baloo Paaji 2', sans-serif;
