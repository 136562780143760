@import '../Variables.scss';
.animatedContainer {
  display: none;
}
.animatedContainer_title {
  display: none;
}
.sectionMyProjectsContainer {
  min-height: 100px;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  background-color: white;
  &_title {
    font-family: $font-family;
    color: $black-color;
    font-size: 1.4rem;
    font-weight: 600;
    width: 18rem;
    background-color: white;
    @media (min-width: 768px) {
      font-size: 1.8rem;
    }
  }
  &_article {
    display: flex;
    flex-direction: column;

    align-items: center;
    width: 15rem;
    gap: 10px;

    &--block {
      height: 3.8rem;
      width: 100%;
      border: 2px solid $primary-color;
      border-radius: 10px;
      padding: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr;
      @media (min-width: 768px) {
        width: 83%;
      }
    }
    &--blockImg {
      width: 6.4rem;
      height: 4.3rem;
      border-radius: 10px;
      border: 1px solid $primary-color;
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 4;
      transform: translateY(-4px);
    }
    &--blockName {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2;
      font-family: $font-family;
      font-weight: 600;
      font-size: 1rem;
      color: $primary-color;
    }
    &--blockInfo {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
      font-size: 0.9rem;
      font-family: $font-family;
      color: $primary-color;
    }
    &--btn {
      background-color: transparent;
      border-radius: 20px;
      border: 1px solid $primary-color;
      color: $primary-color;
      height: 30px;
      width: 130px;
    }
    &--btn:hover {
      color: white;
      background-color: $primary-color;
    }
    &--btnLink {
      text-decoration: none;
    }
    &--btnLink:hover {
      color: white;
    }
  }
}
@media (min-width: 768px) {
  .sectionMyProjectsContainer {
    display: none;
  }
  .animatedContainer_title {
    font-family: $font-family;
    font-size: 2rem;
    font-weight: 600;
    color: $black-color;
    background-color: white;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 5rem;
  }
  body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  .animatedContainer {
    position: relative;
    background-color: white;
    opacity: 0.999;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    transform-style: preserve-3d;
    perspective: 500px;
    margin: auto;
    padding-bottom: 3rem;
    max-width: 1200px;
  }
  .animatedContainer .box {
    position: relative;
    width: 280px;
    height: 200px;
    background: #000;
    transition: 0.5s;
    transform-style: preserve-3d;
    overflow: hidden;
    margin: 45px 20px;
  }
  .animatedContainer:hover .box {
    transform: rotateY(25deg);
  }
  .animatedContainer .box:hover ~ .box {
    transform: rotateY(-25deg);
  }
  .animatedContainer .box:hover {
    transform: rotateY(0deg) scale(1.25);
    z-index: 1;
    box-shadow: 0 25px 40px rgba(0, 0, 0, 0.5);
  }
  .animatedContainer .box .imgBx {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .animatedContainer .box .imgBx:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, white, $black-color);
    z-index: 1;
    opacity: 0;
    transition: 0.5s;
    mix-blend-mode: multiply;
  }
  .animatedContainer .box:hover .imgBx:before {
    opacity: 1;
  }
  .animatedContainer .box .imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .animatedContainer .box .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    padding: 20px;
    align-items: flex-end;
    box-sizing: border-box;
  }
  .animatedContainer .box .content h2 {
    color: #fff;
    transition: 0.5s;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-size: 20px;
    transform: translateY(200px);
    transition-delay: 0.3s;
  }
  .animatedContainer .box:hover .content h2 {
    transform: translateY(0px);
  }
  .animatedContainer .box .content p {
    color: #fff;
    transition: 0.5s;
    font-size: 14px;
    transform: translateY(200px);
    transition-delay: 0.4s;
  }
  .animatedContainer .box:hover .content p {
    transform: translateY(0px);
  }
}
