@import '../Variables.scss';

/* .underConstrucion {
  width: 100%;
  max-width: 1200px;
  margin-top: 3rem;
} */

.myStoreContainer {
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding-top: 6rem;
  width: 100vw;

  gap: 2rem;
  &_title {
    display: flex;
    font-family: $font-family;
    font-size: 2rem;
    width: 100%;
    justify-content: center;
    color: $primary-color;
  }
  &_resume {
    font-family: $font-family;
    color: $black-color;
    padding: 1rem;
    line-height: 1.5rem;
    background-color: $primary-color-3;
    box-shadow: 0 1px 8px 1px #1d313181;
    margin: auto;
    //width: 100%;
  }
  &_skillsTitle {
    font-family: $font-family;
    color: $black-color;
    margin-left: 1rem;
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: -1rem;
  }
  &_skills {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 2rem;
    @media screen and (min-width: 768px) {
      justify-content: left;
      margin-left: 1rem;
      width: 50%;
    }
    &--icon {
      width: 3rem;
    }
  }
  &_link {
    margin: auto;
    width: 130px;
    height: 30px;
    background-color: $primary-color;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    color: white;
    @media screen and (min-width: 768px) {
      transform: translate(200%, -480%);
    }

    &--btn {
      margin: auto;
      width: 130px;
      height: 30px;
      background-color: $primary-color;
      border: none;
      border-radius: 15px;
      cursor: pointer;
      color: white;
    }
    &--icon {
      transform: translateY(0.08rem);
      margin-right: 0.3rem;
    }
  }
  &_img {
    view-timeline-name: --image;
    view-timeline-axis: block;

    animation-timeline: --image;
    animation-name: show;

    animation-range: entry 25% cover 50%;
    animation-fill-mode: both;
    @media screen and (min-width: 768px) {
      transform: translateY(-20%);
    }
  }
}

@keyframes show {
  from {
    opacity: 0;
    scale: 25%;
  }

  to {
    opacity: 1;
    scale: 100%;
  }
}
