.footerContainer {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 5.5rem;
  background-color: $primary-color;
  color: white;
  font-family: $font-family;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
  max-width: 1200px;
  margin: auto;
  margin-bottom: 0;
  &_list {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    font-size: 1.1rem;
    cursor: pointer;
    padding: 10px;

    &--link {
      text-decoration: none;
      color: white;
    }
    &--link:hover {
      text-decoration-line: underline;
    }
  }

  &_phrase {
    font-size: 0.7rem;
  }
  &_phrase2 {
    font-size: 0.7rem;
    &--heartIcon {
      color: $tertiary-color;
    }
  }
}
@media (min-width: 768px) {
  .footerContainer {
    width: 100%;
    height: 7rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;

    &_list {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 6;
      display: flex;
      flex-direction: column;
      gap: 7px;
      font-size: 1rem;
      align-items: center;
      border-right: 1px solid white;
    }
    &_phrase {
      grid-column-start: 2;
      grid-column-end: 4;
      grid-row-start: 3;
      grid-row-end: 4;
      margin: auto;
    }
    &_phrase2 {
      grid-column-start: 2;
      grid-column-end: 4;
      grid-row-start: 4;
      grid-row-end: 5;
      margin: auto;
    }
  }
}
@media (min-width: 1020px) {
  .footerContainer {
    &_list {
      display: flex;
      flex-direction: row;
      gap: 15px;
      font-size: 1.2rem;
    }
  }
}
