@import '../Variables.scss';

.carouselContainer {
  display: flex;
  flex-direction: column;

  align-items: center;
  gap: 3rem;
  width: 100vw;
  height: 80vh;
  position: relative;
  max-width: 1200px;
  margin: 6rem auto 4rem;
  @media (min-width: 768px) {
    margin: 6rem auto;
  }
  &_slider {
    max-width: 100%;
    justify-self: center;

    @media (min-width: 768px) {
      margin: 0 auto;
      width: 60%;
    }

    &--slide {
      display: flex;
      justify-content: center;
      justify-items: center;
      justify-self: center;

      @media (min-width: 1024px) {
        justify-self: center;
        margin: 0 auto;
        width: 80%;
        max-width: 1200px;
      }
    }
    &--img {
      max-width: 100%;
      height: auto;
      border-radius: 10px;
      border: 4px solid $primary-color;
      box-sizing: border-box;
      margin: auto;
    }
  }
}

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  background-color: transparent;
  padding: 10px;
  font-size: 2rem;
  border-radius: 50%;
  color: $primary-color;
  display: none;
  @media (min-width: 768px) {
    display: inline;
    max-width: 1200px;
    justify-self: center;
  }
}

.custom-prev {
  left: -3rem;
  @media (min-width: 1024px) {
    left: 0;
  }
}

.custom-next {
  right: -3rem;
  @media (min-width: 1024px) {
    right: 0;
  }
}
