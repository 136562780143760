@import '../Variables.scss';

.landingPage {
  width: 100vw;
  height: 100%;
  //margin: 5.5rem 0 0 0;
  max-width: 1200px;
}

.fixedImgContainer {
  width: 100%;
  max-width: 1200px;
  height: 15rem;
  background-color: transparent;
}
.fixedImg {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  justify-items: center;
  z-index: -1;
  position: fixed;
  top: 0;
}
