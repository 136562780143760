@import './Variables.scss';

.aboutContainer {
  max-width: 1200px;
  margin: auto;
  display: flex;

  flex-direction: column;
  padding-top: 4.5rem;
  width: 100vw;
  &_intro {
    width: 100vw;
    position: relative;
    &--img {
      width: 100%;
      height: 100%;
      max-width: 1200px;
      opacity: 0.5;
    }
    &--text {
      font-family: $font-family;
      font-size: 0.45rem;
      width: 45%;
      max-width: 500px;

      height: 85%;
      background-color: $primary-color-3;
      border-radius: 5px;
      padding: 5px;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-95%, -50%);
      line-height: 0.7rem;
      display: flex;
      opacity: 0.7;
      align-items: center;
      @media (min-width: 600px) {
        font-size: 1rem;
        line-height: 1.6rem;
        padding: 1rem;
      }
      @media (min-width: 1024px) {
        font-size: 1.4rem;
        line-height: 1.8rem;
        padding: 1rem;
        max-width: calc(50% - 30px);
      }
    }
  }
  &_skills {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 100%;

    &--line {
      border-top: 1px solid $primary-color-3;
      width: 100%;
      @media (min-width: 768px) {
        width: 50%;
      }
    }
    &--title {
      font-family: $font-family;
      color: $black-color;
      width: 8rem;
      height: 1.7rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $primary-color-3;
      border-radius: 15px;
      transform: translateY(-50%);
      margin-bottom: 5px;
      margin: auto;
    }
    &--designIcons {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 2rem;
      gap: 1rem;
    }
    &--separateLine {
      border-top: 1.5px solid $primary-color;
      width: 50%;
      display: flex;
      justify-content: left;
      align-self: flex-end;
      margin-bottom: 1rem;
    }
    &--developmentIcons {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;

      gap: 1rem;
      margin: 0 2rem 0 2rem;
    }
  }
  &_softSkills {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;
    width: 100%;

    &--title {
      font-family: $font-family;
      color: $black-color;
      width: 8rem;
      height: 1.7rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $primary-color-3;
      border-radius: 15px;
      transform: translateY(-50%);
      margin-bottom: 2rem;
      margin: auto;
    }
    &--list {
      font-family: $font-family;
      letter-spacing: 0.5px;
      font-size: 1rem;
      color: $black-color;
      display: flex;
      flex-direction: column;
      margin-left: 3rem;
      width: 15rem;
      margin-bottom: 2rem;
    }
  }
  &_languages {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1rem;
    width: 100%;

    &--title {
      font-family: $font-family;
      color: $black-color;
      width: 8rem;
      height: 1.7rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $primary-color-3;
      border-radius: 15px;
      transform: translateY(-50%);
      margin-bottom: 2rem;
      margin: auto;
    }
    &--list {
      font-family: $font-family;
      letter-spacing: 0.5px;
      font-size: 1rem;
      color: $black-color;
      display: flex;
      flex-direction: column;
      margin-left: 3rem;
      width: 9.5rem;
      margin-bottom: 4rem;
    }
  }
}
.icon {
  width: 2rem;
  height: 2rem;
  @media (min-width: 768px) {
    width: 3rem;
    height: 3rem;
    margin-top: 1rem;
  }
}
.breakPoint {
  transform: translate(-0.5rem, -0.55rem);
  color: $primary-color;
}

.li::before {
  content: '•';
  color: $black-color;
  margin-right: 0.5rem;
}
@media (min-width: 1024px) {
  .softAndLanguages {
    display: flex;
    flex-direction: row;
  }
  .languages {
    transform: translate(100%, 10px);
    width: 50%;
  }
  .aboutContainer_languages--title {
    transform: translate(-30px, -35px);
  }
  .aboutContainer_languages--list {
    transform: translate(140px, -40px);
  }
}
